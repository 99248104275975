import { createSlice } from '@reduxjs/toolkit'

const EXPIRATION_TIME = 5 * 60 * 60 * 1000 // 5 hours (5 * 60 * 60 * 1000 ms)

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: null,
        signedIn: false,
        expiry: null, // Store expiry timestamp
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.token = action.payload
            state.signedIn = true
            state.expiry = Date.now() + EXPIRATION_TIME // Set expiry time
        },
        onSignOutSuccess: (state) => {
            state.token = null
            state.signedIn = false
            state.expiry = null
        },
        checkTokenExpiry: (state) => {
            if (state.expiry && Date.now() > state.expiry) {
                state.token = null
                state.signedIn = false
                state.expiry = null
            }
        },
    },
})

export const { onSignInSuccess, onSignOutSuccess, checkTokenExpiry } =
    sessionSlice.actions

export default sessionSlice.reducer
