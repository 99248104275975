import Cookies from 'js-cookie'

const verified_brand_url = Cookies.get('verified_brand_url')
// console.log('userRole', userRole)

let unAuthenticatedEntryPath = `/${verified_brand_url}/login` // Default path

// if (userRole) {
//     switch (userRole) {
//         case 'assessor':
//             unAuthenticatedEntryPath = '/auditor-login'
//             break
//         case 'School':
//             unAuthenticatedEntryPath = '/school-login'
//             break
//         case 'qc':
//             unAuthenticatedEntryPath = '/qc-login'
//             break
//         case 'admin':
//         case 'LeadA':
//             unAuthenticatedEntryPath = '/sign-in'
//             break
//         default:
//             unAuthenticatedEntryPath = '/sign-in'
//             break
//     }
// }

const appConfig = {
    apiPrefix: process.env.REACT_APP_API_BASE || '/api',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: unAuthenticatedEntryPath,
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}

export default appConfig
