export const getUserDetails = () => {
    // Get data from localStorage (use "admin" instead of "auth")
    const adminData = localStorage.getItem('admin')

    if (!adminData) return null // Return null if no admin data is found

    try {
        // Parse the JSON string
        const parsedAdmin = JSON.parse(adminData)

        // Since the data is double-encoded, parse it again
        const parsedAuth = JSON.parse(parsedAdmin.auth)

        // Extract user details
        const user = parsedAuth?.user

        return {
            id: user?.id || null,
            name: user?.name || 'Guest',
            brand_slug: user?.brand_slug || 'na',
            brand_logo: user?.brand_logo || 'na',
            brand_logo_width: user?.brand_logo_width || '0',
            email: user?.email || 'No Email',
            roleId: user?.role_id || null,
            status: user?.status || 'Inactive',
        }
    } catch (error) {
        console.error('Error parsing admin auth data:', error)
        return null
    }
}
